<template>
  <small>
    <strong>Text -</strong> <i>Can input alphanumeric data such as name and address.</i> <br>
    <strong>Dropdown -</strong> <i>Presents a list of options in a vertical menu that appears when activated.</i> <br>
    <strong>Number -</strong> <i>Can input numeric data; best for quantity or any whole number field.</i> <br>
    <strong>Date -</strong> <i>Renders a calendar that allows the user to select a date and optionally a time.</i> <br>
    <strong>Textarea -</strong> <i>A multi-line plain-text editing control; best for description & particulars field.</i> <br>
    <strong>Input Search -</strong> <i>Presents a list of options in a modal view that appears when activated; <br>best for big amount of data.</i> <br>
    <strong>Label -</strong> <i>Typically refers to a designated area or element in a form.</i> <br>
    <strong>Number Format -</strong> <i>Can input numeric data; best for amount or any number field that <br>has decimal.</i> <br>
</small>
</template>

<script>
export default {
    name: 'FieldHelp'
}
</script>

<style>

</style>