<template>
    <div>
        <CRow>
            <CCol lg="3">
                Field Type
            </CCol>
            <CCol lg="9">
                <!-- :class="!group_required ? 'has-error' : 'has-success'" -->
                <v-select 
                    placeholder="Select one."
                    label="name"
                    :options="types"
                    v-model="drpdwn_type"
                    :class="!drpdwn_type ? 'has-error' : 'has-success'"
                />
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol lg="3">
                Display Name
            </CCol>
            <CCol lg="9">
                <CInput 
                    :lazy="false"
                    :value.sync="$v.field.display_name.$model"
                    :isValid="checkIfValid($v.field, 'display_name')"
                    type="text" 
                    placeholder="Enter display name." 
                    autocomplete="off"  
                    v-model="field.display_name"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol lg="3">
                Parameter Name
            </CCol>
            <CCol lg="9">
                <CInput 
                    :lazy="false"
                    :value.sync="$v.field.parameter_name.$model"
                    :isValid="checkIfValid($v.field, 'parameter_name')"
                    type="text" 
                    placeholder="Enter parameter name." 
                    autocomplete="off"  
                    v-model="field.parameter_name"
                />
            </CCol>
        </CRow>
        <template v-if="drpdwn_type.name != 'label'">
            <CRow>
                <CCol lg="3">
                    Default Value
                </CCol>
                <CCol lg="9">
                    <CInput 
                        type="text" 
                        placeholder="Enter default value." 
                        autocomplete="off"  
                        v-model="field.default_value"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol lg="3">
                    Source
                </CCol>
                <CCol lg="9">
                    <v-select 
                        :disabled="field.field_type_name != 'dropdown' && field.field_type_name != 'input_search'"
                        placeholder="Select one."
                        label="name"
                        :options="sources"
                        v-model="drpdwn_source_type"
                        :class="!drpdwn_source_type ? 'has-error' : 'has-success'"
                    />
                </CCol>
            </CRow>
            <br>
            <CRow>
                <CCol lg="3">
                </CCol>
                <CCol lg="9">
                    <template v-if="field.source_type == 'api'">
                        <v-select 
                            :disabled="field.source_type=='none'"
                            placeholder="Select method..."
                            label="Method"
                            v-model="field.api_method"
                            :options="['GET','POST']"
                            :class="!field.api_method ? 'has-error' : 'has-success'"
                        />
                        <br>
                        <CInput 
                            :disabled="field.source_type=='none'"
                            :lazy="false"
                            :value.sync="$v.field.source.$model"
                            :isValid="checkIfValid($v.field, 'source')"
                            type="text" 
                            placeholder="Enter source." 
                            autocomplete="off"  
                            v-model="field.source"
                        />
                    </template>
                    <!-- <CInput 
                        v-if="field.source_type == 'api'"
                        :disabled="field.source_type=='none'"

                        :lazy="false"
                        :value.sync="$v.field.api_method.$model"
                        :isValid="checkIfValid($v.field, 'api_method')"

                        type="text" 
                        placeholder="Enter API Method" 
                        autocomplete="off"  
                        v-model="field.api_method"
                    /> -->
                    
                    <template v-if="field.source_type == 'excel' || field.source_type == 'json'">
                        <!-- <CFormInput type="file" id="formFile" label="Default file input example" /> -->
                        <input 
                            :class="field.source_file ? 'external_file_valid' : 'external_file_invalid'"
                            ref="external_file" 
                            type="file" 
                            v-on:change="onFileChange"
                            :accept="field.source_type == 'excel' ? '.xlsx' : '.json'"
                        />
                        &nbsp;
                        <a-popover title="NOTE:" placement="topRight">
                            <template slot="content">
                            <h6>Excel file guidelines:</h6>
                            <span>* File type must be excel.</span> <br>
                            <span>* File extension must be .xlsx.</span><br>
                            <span>* File size must be less than 5mb.</span><br><br>
                            <span style="color: red;">> Only read Sheet 1 and Column A, values <br> 
                                &nbsp;&nbsp; that are not in the said sheet and column <br>
                                &nbsp;&nbsp; will be discarded.  </span><br>
                            
                            </template>
                                <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                        </a-popover>
                        <br>
                        <br>
                    </template>
                    <template v-if="field.source_type == 'static'">
                        <v-select 
                            taggable 
                            multiple push-tags 
                            v-model="field.source_static"
                            :class="field.source_static.length <= 0  ? 'has-error' : 'has-success'"
                        />
                        <br>
                    </template>
                </CCol>
            </CRow>
            <CRow>
                <CCol lg="3">
                    Validations
                </CCol>
                <CCol lg="9">
                    <CInput 
                        type="text" 
                        placeholder="(e.g 'required', 'max:20')" 
                        autocomplete="off"  
                        v-model="field.validation"
                    />
                </CCol>
            </CRow>
        </template>
        <template v-else>
            
            <CRow>
                <CCol lg="3">
                    Label Content
                </CCol>
                
                <CCol lg="9">
                    Label Format: 
                    <p-radio class="p-icon p-curve p-pulse p-bigger" name="radio66" color="success" value="left" v-model="field.label_format">
                        <font-awesome-icon class="icon" icon="check"/>
                        Left
                    </p-radio>
                    <p-radio class="p-icon p-curve p-pulse p-bigger" name="radio66" color="success"  value="center" v-model="field.label_format">
                        <font-awesome-icon class="icon" icon="check"/>
                        Center
                    </p-radio>
                    <p-radio class="p-icon p-curve p-pulse p-bigger" name="radio66" color="success"  value="right" v-model="field.label_format">
                        <font-awesome-icon class="icon" icon="check"/>
                        Right
                    </p-radio>
                </CCol>
            </CRow>
            <br>
            <CRow>
                <CCol lg="12">
                    <CTextarea class="grp-text-area-in-field" v-model="field.label_value">

                    </CTextarea>
                </CCol>
            </CRow>
            <hr>
            <br>
            <CRow>
                <CCol lg="12">
                    <h4>Label Preview</h4>
                    <!-- <div style="white-space: pre-line;">{{field.label_value}}</div> -->
                    <div :style="`white-space: pre-line; text-align:${field.label_format}` ">{{field.label_value}}</div>
                </CCol>
            </CRow>
        </template>
        <CRow>
            <CCol lg="12">
                <CButton 
                    class="float-right"
                    color="primary" 
                    shape="pill" 
                    :disabled="!isValid"
                    @click="isValid ? createNewField() : ''"
                >
                    <font-awesome-icon icon="save"/> Save
                </CButton>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import { required, requiredIf, url} from "vuelidate/lib/validators"
export default {
    name: 'FieldCreateForm',
    data() {
        return {
            types: [],


            
            drpdwn_type:'',
            drpdwn_source_type:'',

            field: {
                field_type_id: '',
                field_type_name: '',
                display_name: null,
                parameter_name: null,
                source_type: '',
                default_value: '',
                
                source: null,
                api_method:null,
                source_static: [],
                source_file: null,
                validation: null,
                label_value:null,
                label_format: null,
            },

            form_reset: false,
        }
    },
    validations: {
        field: {
            field_type_id: { required, },
            display_name: { required, },
            parameter_name: { required },
            source_type: { required, },
            source: {
                required: requiredIf(function (){
                   return this.field.source_type == 'api'
                }),
                url,
            },
            api_method: {
                required: requiredIf(function (){
                   return this.field.source_type == 'api'
                }),
            },
            label_value: {
                required: requiredIf(function (){
                   return this.drpdwn_type.name == 'label'
                }),
            },
            label_format: {
                required: requiredIf(function (){
                   return this.drpdwn_type.name == 'label'
                }),
            },
            source_static: {
                required: requiredIf(function () {
                    return this.field.source_type == 'static'
                })
            },
            source_file: {
                required: requiredIf(function () {
                    return this.field.source_type == 'excel'
                })
            }
        },
    },
    computed: {
        isValid () { return !this.$v.field.$invalid },
        isDirty () { return this.$v.field.$anyDirty },

        sources : function () {
            let custom =  [
                {name: 'api'}, 
                {name: 'static'}, 
                {name: 'excel'},
                // {name: 'json'}
            ]

            if(this.field.field_type_name != 'dropdown' && this.field.field_type_name != 'input_search'  && this.form_reset) {
                custom.push({ name: 'none' })
            }
            
            return custom;
        }
    },
    created() {
        this.$v.field.$touch()
        this.getFieldTypes();
    },
    methods: {
        clearExternalFile:function()
        {
            const input = this.$refs.external_file;
            input.type = 'text';
            input.type = 'file';
        },
        onFileChange:function (e)
        {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.clearExternalFile();
                this.field.source_file = null;
                return;
            }

            if(files[0].type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') 
            {
                this.$swal({
                    customClass: 'ta-left',
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 5000,
                    icon: 'error',
                    title: `Invalid file. Only accept .xlsx type.`,
                    timerProgressBar: true,
                })

                this.field.source_file = null;
                this.clearExternalFile();
                return;
            }

            if (files[0].size / Math.pow(1024,1) > 5000) {
                this.$swal({
                    customClass: 'ta-left',
                    toast: true,
                    position: 'top-right',
                    timer: 5000,
                    icon: 'error',
                    title: `File is too large. Maximum size is 5MB.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })

                this.field.source_file = null;
                this.clearExternalFile();
                return;
            }

            this.field.source_file = files[0]
        },
        getFieldTypes: function () {
            this.$Progress.start()
            axios.get('/form-managements/field-type/active-list', {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    this.types = response.data.data
                    this.$Progress.finish()
                }
            })
        },
        createNewField: function() {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: "You are trying to create new Field",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    
                    let data = new FormData();
                    data.append('api_method', this.field.api_method);
                    data.append('display_name', this.field.display_name);
                    data.append('field_type_id', this.field.field_type_id);
                    data.append('field_type_name', this.field.field_type_name);
                    data.append('label_format', this.field.label_format);
                    data.append('label_value', this.field.label_value);
                    data.append('parameter_name', this.field.parameter_name);
                    data.append('source', this.field.source);
                    data.append('source_file', this.field.source_file);
                    data.append('source_static', this.field.source_static.length > 0 ? this.field.source_static : null);
                    data.append('source_type', this.field.source_type);
                    data.append('validation', this.field.validation);
                    data.append('default_value', this.field.default_value);

                    if(!this.field.validation) {
                        delete this.field.validation;
                        data.delete("validation");
                    }
                    if(!this.field.default_value) {
                        delete this.field.default_value;
                        data.delete("default_value");
                    }


                    this.$Progress.start()
                    return axios.post('/form-managements/field/store', data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.form_reset= true;
                            if(this.field.display_name != 'n/a') {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    html: `<b>${this.field.display_name}</b> has been added.`,
                                })
                            }
                            else {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    html: `New label has been added`,
                                })
                            }
                            
                            this.drpdwn_type = ''
                            this.drpdwn_source_type = ''
                            this.drpdwn_default_value = ''
                            this.m_value = [];
                            this.$v.$reset()
                            this.$v.$touch()
                            this.field = this.clearForm();
                            this.$Progress.finish()
                            setTimeout(() => {
                                this.form_reset = false;
                            }, 500)
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        clearForm: function (){
            return {
                field_type_id: '',
                display_name: null,
                parameter_name: null,
                source_type: '',
                source: '',
                default_value: '',
                source_file: null,
                api_method: null,
                source_static: [],
                validation: null,
                label_value: null,
                label_format: null,
            }
        },
    },
    watch: {
        drpdwn_type: function (value) {
            this.m_value = []
            this.field.source_static = [];
            this.field.source_file = [];
            this.field.field_type_id = null;
            this.field.field_type_name  = null;
            if(value) {
                this.field.field_type_id = value.id,
                this.field.field_type_name = value.name
            }

            // if(value.name == 'label') {
            //     this.field.display_name = 'n/a'
            //     this.field.parameter_name = 'n/a'
            // }

            if(value.name != 'dropdown' && value.name != 'input_search' && !this.form_reset) {
                this.drpdwn_source_type = {name: 'none'};
            } else {
                // this.source = this.source.filter(function(el) { return el.name != "none"; });
                this.drpdwn_source_type = ''
            }
        },
        drpdwn_source_type: function (value) {
            this.m_value = []
            this.field.source_static = [];
            this.field.source_type = null;
            this.field.source_file = null;
            this.field.source = null    
            if(value) this.field.source_type = value.name
        },
    }
}
</script>
<style>
    .external_file_valid {
        border: solid green 1px;
        border-radius: 5px;
    }
    .external_file_invalid {
        border: solid red 1px;
        border-radius: 5px;
    }
</style>